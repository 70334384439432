import React from "react";

export default function UnderConstruction() {

    React.useEffect(() => {
        const stopScroll = (e) => {
            e.preventDefault();
        }
        window.addEventListener('scroll', stopScroll);
        return window.removeEventListener('scroll', stopScroll);
    }, []);

    return (
        <div id="intro" style={{ display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <div>
                <h2>SITE UNDER CONSTRUCTION</h2>
            </div>
        </div>
    );
}