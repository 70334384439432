export default class AnimationHelper
{
    static lerp = (currentValue, targetValue, speed) => {
        return currentValue + (targetValue - currentValue) * speed;
    }

    static lerpPosition = (currentPosition, targetPosition, speed) => {
        return {
            x: this.lerp(currentPosition.x, targetPosition.x, speed),
            y: this.lerp(currentPosition.y, targetPosition.y, speed),
            z: this.lerp(currentPosition.z, targetPosition.z, speed)
        }
    }
}