import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Color } from 'three';
import Raymarching from './Raymarching.jsx';
import CameraControls from './CameraControls.jsx';
import { Perf } from 'r3f-perf';
import Refraction from "./Refraction.jsx";



export default function Experience()
{
    const three = useThree();

    // useEffect(() => {
    //     three.gl.setClearColor(new Color('#000000'))
    // }, []);

    return <>

        {/* <Perf position="bottom-right" /> */}
        {/* <CameraControls /> */}
        <Raymarching />
        {/* <OrbitControls /> */}
        {/* <Refraction /> */}
    </>
}