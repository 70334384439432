import NavBar from '../common/NavBar';
import IntroSection from './IntroSection.jsx';
import ToolsSection from './ToolsSection.jsx';
import FeaturedProjects from './FeaturedProjects.jsx';
import UnderConstruction from "./UnderConstruction.jsx";

export default function Home()
{
    return (
        <>
            <NavBar />
            <UnderConstruction />
            {/* <IntroSection />
            <ToolsSection />
            <FeaturedProjects /> */}
        </>
    )
}