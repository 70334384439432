import { Vector3 } from 'three';

const worldPointerPos = new Vector3();
const mousePos = new Vector3();

export default class PointerHelper
{
    static getWorldPointerPosition = (normalizedPointerPosition, camera) =>
    {
        mousePos.set(normalizedPointerPosition.x, normalizedPointerPosition.y, 0.5);
        mousePos.unproject( camera );
        mousePos.sub( camera.position ).normalize();

        const distance = -camera.position.z / mousePos.z;
        worldPointerPos.copy( camera.position ).add( mousePos.multiplyScalar( distance ) );

        return worldPointerPos;
    }
}