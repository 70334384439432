varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vWorldPos;
varying vec3 vCamPos;

void main() 
{
    vUv = uv;
    vNormal = normal;

    vWorldPos = (modelMatrix * vec4(position, 1.0)).xyz; // same as model pos
    vCamPos = cameraPosition; // use cam pos directly for world space

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}