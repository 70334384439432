import { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from './ThemeContext.js';

export default function NavBar()
{
    const [ themeContext, setThemeContext ] = useContext(ThemeContext);

    const onClick = useCallback(() => {
        // setThemeContext( themeContext == 'light' ? 'dark' : 'light' );
    }, [themeContext]);

    return (
        <nav>
            <Link to={'/'}>
                <div className="header" onClick={ () => onClick() }>
                    <img src="/samstrong-design.png" alt="logo" />
                    <h1>SAM <span>STRONG</span></h1>
                    {/* { themeContext == 'light' ? <p>light</p> : <p>dark</p> } */}
                </div>
            </Link>
            {/* <ul>
                <li><Link to={'/all-projects'}>//01 ALL PROJECTS</Link></li>
                <li><Link to={'/about-me'}>//02 ABOUT ME</Link></li>
            </ul> */}
        </nav>
    )
}