import './style.css'
import { useState } from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Canvas, useThree } from '@react-three/fiber'
import Experience from './home/Experience.jsx'
import { Leva } from 'leva'
import Home from './home/Home.jsx';
import AllProjects from './all-projects/AllProjects';
import AboutMe from './about-me/AboutMe';
import ThemeContext from './common/ThemeContext.js';
import { AppContextProvider } from './common/AppContext.jsx';


const root = ReactDOM.createRoot(document.querySelector('#root'));

const App = () => {
    const theme = useState('dark');

    return (
        <>
            <Leva hidden={ true } />
            <AppContextProvider>
                <ThemeContext.Provider value={ theme }>
                    <div className="webgl" style={ { backgroundColor: theme[0] == 'dark' ? '#000000' : '#ffffff' } }>
                        <Canvas 
                            camera={ {
                                fov: 45,
                                near: 0.1,
                                far: 2000,
                                position: [ 0, 0, -10 ]
                            } }
                            gl={ { 
                                antialias: true,
                                alpha: true
                            } }
                        >
                            <Experience />
                        </Canvas>
                    </div>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={ <Home /> } />
                            <Route path="/all-projects" element={ <AllProjects /> } />
                            <Route path="/about-me" element={ <AboutMe /> } />
                        </Routes>
                    </BrowserRouter>
                </ThemeContext.Provider>
            </AppContextProvider>
        </>
    )
}

root.render( <App /> );