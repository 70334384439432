#define MAX_STEPS 100.0
#define MAX_DIST 20.0
#define SURF_DIST 0.001

#define SMOOTH_AMOUNT 0.75
#define FRESNEL_RAMP 0.66
#define FRESNEL_INTENSITY 1.15
	
uniform float uTime;
uniform vec3 uCamPos;
uniform vec3 uMousePos;

uniform vec3 uColor1;
uniform vec3 uColor2;

uniform samplerCube uReflMap;
uniform samplerCube uEnvMap;

varying vec3 vNormal;
varying vec3 vWorldPos;
varying vec3 vCamPos;

struct sphereObj
{
    float radius;
    vec3 pos;
};
  

float sphere(vec3 p, float r, vec3 pos) {
    p -= pos;
    float d = length(p) - r;
    return d;
}
	
float sdBoxFrame( vec3 p, vec3 b, float e )
{
    p = abs(p  )-b;
    vec3 q = abs(p+e)-e;
    return min(min(
        length(max(vec3(p.x,q.y,q.z),0.0))+min(max(p.x,max(q.y,q.z)),0.0),
        length(max(vec3(q.x,p.y,q.z),0.0))+min(max(q.x,max(p.y,q.z)),0.0)),
        length(max(vec3(q.x,q.y,p.z),0.0))+min(max(q.x,max(q.y,p.z)),0.0));
}

float smin(float a, float b, float k) {
    float h = clamp(0.5 + 0.5 * (b - a) / k, 0.0, 1.0);
    return mix(b, a, h) - k * h * (1.0 - h);
}

float getDist(vec3 p) {
    
    sphereObj so1;
    so1.radius = sin(uTime * 0.2) * 0.3 + 2.4;
    so1.pos = vec3(sin(uTime / 2.0) * 2.25, cos(uTime / 2.0) * 2.25, cos(uTime / 2.0) * 2.25);
    float s1 = sphere(p, so1.radius, so1.pos);
    
    sphereObj so2;
    so2.radius = cos(uTime * 0.25) * 0.5 + 2.0;
    so2.pos = vec3(sin(uTime / 3.0) * 2.5, cos(uTime / 1.25) * 2.5, sin(uTime / 1.5) * 2.5);
    float s2 = sphere(p, so2.radius, so2.pos);
    
    sphereObj so3;
    so3.radius = cos(uTime * 0.1) * 0.4 + 2.5;
    so3.pos = vec3(cos(uTime / 2.0) * 2.0, sin(uTime / 2.0) * 2.0, sin(uTime / 3.0)) * 2.0;
    float s3 = sphere(p, so3.radius, so3.pos);

    sphereObj mouseObj = sphereObj(1.75, uMousePos);
    float m = sphere(p, mouseObj.radius, mouseObj.pos);
    
    // float b1 = sdBoxFrame(p, vec3(2), 0.75);
		
	float sm1 = smin(s1, s2, SMOOTH_AMOUNT);
	float sm2 = smin(sm1, s3, SMOOTH_AMOUNT);
	// float d = smoothstep(sm2, b1, SMOOTH_AMOUNT);
    float d = smin(sm2, m, SMOOTH_AMOUNT);
    
    return d;
}

#define OUTSIDE 1.0
#define INSIDE -1.0

float rayMarch(vec3 ro, vec3 rd, float SIDE) {
    float dO = 0.0;
    float dS;
    for (float i = 0.0; i < MAX_STEPS; i += 1.0) {
        vec3 p = ro + rd * dO;
        dS = getDist(p) * SIDE;
        dO += dS;
        if (dS < SURF_DIST || dO > MAX_DIST) break;
    }
    return dO;
}

vec3 getNormal(vec3 p) {
    vec2 e = vec2(0.01, 0.0);
    vec3 n = getDist(p) - vec3(
        getDist(p-e.xyy),
        getDist(p-e.yxy),
        getDist(p-e.yyx)
        );
    return normalize(n);
}

float getFresnel(vec3 normal, vec3 ro)
{
    vec3 viewDir = normalize(ro);
            
    float fresnelAmount = 1.0 - max(0.0, dot(normal, viewDir));
    fresnelAmount = pow(fresnelAmount, FRESNEL_RAMP) * FRESNEL_INTENSITY;

    return fresnelAmount;
}

vec3 renderRefraction(vec3 ro, vec3 rd)
{
    vec3 color = vec3(0.0);
    float d = rayMarch(ro, rd, OUTSIDE); // raymarch outside of object towards object

    float IOR = 1.45;

    if (d < MAX_DIST){

        vec3 p = ro + rd * d;
        vec3 n = getNormal(p);
        float f = getFresnel(n, ro);
        vec3 r = reflect(rd, n);

        vec3 rdIn = refract(rd, n, 1.0 / IOR); // ray direction when entering

        vec3 pEnter = p - n * SURF_DIST * 4.0; // ray entry position (origin for next raymarch)
        float dIn = rayMarch(pEnter, rdIn, INSIDE); // inside object

        vec3 pExit = pEnter + rdIn * dIn; // ray exit position
        vec3 nExit = -getNormal(pExit); // normal of the exit point

        vec3 rdOut = refract(rdIn, nExit, IOR); // refact again on the way out
        if (length(rdOut) == 0.0) rdOut = reflect(rdIn, nExit); // if angle is too sharp, reflect instead of refract

        vec3 separationAmt = smoothstep(vec3(0.0), vec3(0.02), vec3(0.002));
        float red = textureCube(uEnvMap, rdOut + separationAmt).r;
        float green = textureCube(uEnvMap, rdOut).g;
        float blue = textureCube(uEnvMap, rdOut - separationAmt).b;
        vec3 reflectTex = vec3(red, green, blue);
        reflectTex *= 4.0;

        // color = vec3(reflectTex);
        // color.rgb = vec3(1.0) * f * mix(uColor1, uColor2, n);

        color = reflectTex;

    } else {
        discard;
    }

    return color;
}

vec3 renderReflection(inout vec3 ro, inout vec3 rd, bool crop)
{
    vec3 color = vec3(0.0);
    float d = rayMarch(ro, rd, OUTSIDE); // raymarch outside of object towards object

    if (d < MAX_DIST){

        vec3 p = ro + rd * d;
        vec3 n = getNormal(p);
        float f = getFresnel(n, ro);
        vec3 r = reflect(rd, n);
        vec3 refTex = textureCube(uEnvMap, r).rgb;
        
        float falloffPower = 2.0;
        float falloff = saturate(pow(1.0 - dot(n, -rd), falloffPower));
        refTex *= falloff;
        refTex *= 1.5;

        f = 1.0 - f;
            
        color.rgb = vec3(1.0) * f * mix(uColor1, uColor2, n);
        color.rgb = refTex;

        ro = p + n * SURF_DIST * 4.0;
        rd = r;

    } else if (crop) {
        discard;
    }

    return color;
}

void main() {
    
    vec3 ro = vCamPos; // use the camera position directly
    vec3 rd = normalize(vWorldPos - ro); // use world space position

    vec3 color = renderRefraction(ro, rd);

    const int NUM_BOUNCES = 2;
    float attn = 0.3;
    for (int i = 0; i < NUM_BOUNCES; i++)
    {
        color += renderReflection(ro, rd, false) * attn;
    }
    

    gl_FragColor = vec4(color, 1.0);
}