import { Center, CubeCamera, Text3D, shaderMaterial, useCubeTexture, Environment, Html } from "@react-three/drei";
import { extend, useFrame, useLoader, useThree } from '@react-three/fiber';
import React, { useRef, useState, Suspense, useEffect } from "react";
import { useControls } from 'leva';
import vertexShader from "../shaders/refraction-reflection/vertex.glsl";
import fragmentShader from "../shaders/refraction-reflection/fragment.glsl";
import useMousePosition from "../useMousePosition.js";
import PointerHelper from '../helpers/PointerHelper';
import AnimationHelper from '../helpers/AnimationHelper';
import { Color, MeshStandardMaterial, Vector3 } from 'three';

const RaymarchMaterial = shaderMaterial(
    {
        uTime: 0,
        uCamPos: new Vector3(),
        uMousePos: new Vector3(),
        uColor1: new Color('#ffffff'),
        uColor2: new Color('#ffffff'),
        uReflMap: null,
        uEnvMap: null
    },
    vertexShader,
    fragmentShader
);

extend({ RaymarchMaterial });


export default function Raymarching()
{
    const raymarchMaterial = useRef();
    const text = useRef();
    const [ _, normalizedMousePos ] = useMousePosition();
    const [ smoothMousePos, setSmoothMousePos ] = useState({ x: 0, y: 0, z: 0 });

    const worldMouseTarget = new Vector3();
    const mousePos = new Vector3();
    // let speed = 0.025;

    const three = useThree();

    const { color1, color2 } = useControls('blobs', {
        color1: '#ffffff',
        color2: '#ffffff'
    });

    const envMap = useCubeTexture(
        [ 
            'px.png',
            'nx.png',
            'py.png',
            'ny.png',
            'pz.png',
            'nz.png',
        ],
        {
            path: '/environment/'
        }
    );

    const mouseMoveTimerRef = React.useRef(5);

    React.useEffect(() => {
        mouseMoveTimerRef.current = 0;
    }, [normalizedMousePos]);

    useFrame((state, delta) =>
    {
        const time = state.clock.elapsedTime;
        const camPos = state.camera.position;
        mouseMoveTimerRef.current += delta;

        // filler anim when no mouse movement
        const fillerAnimX = Math.sin(time);
        const fillerAnimY = Math.cos(time);
        const fillerAnimZ = 0.5;
        const fillerAnimPos = new Vector3(fillerAnimX, fillerAnimY, fillerAnimZ);
        
        // mouse anim
        const worldMousePos = PointerHelper.getWorldPointerPosition(normalizedMousePos, state.camera);

        const targetPos = mouseMoveTimerRef.current >= 5 ? fillerAnimPos : worldMousePos;
        const speed = mouseMoveTimerRef.current >= 5 ? 0.01 : 0.025;

        setSmoothMousePos(AnimationHelper.lerpPosition(smoothMousePos, targetPos, speed));

        raymarchMaterial.current.uTime = time;
        raymarchMaterial.current.uCamPos = camPos;
        raymarchMaterial.current.uMousePos = smoothMousePos;
        raymarchMaterial.current.uColor1 = new Color(color1);
        raymarchMaterial.current.uColor2 = new Color(color2);

        // static texture
        if (envMap) 
        {
            raymarchMaterial.current.uEnvMap = envMap;
        }
    });


    return (
        <Suspense fallback={ null } >
            <CubeCamera resolution={ 1024 }>
                {(texture) => ( 
                    <>
                        <Environment background={ false } files={ [ 
                            'px.png',
                            'nx.png',
                            'py.png',
                            'ny.png',
                            'pz.png',
                            'nz.png',
                        ] } />
                        <mesh scale={ [ 15,15,15 ] }>
                            <boxGeometry />
                            <raymarchMaterial ref={ raymarchMaterial } envMap={ texture } uniforms-uReflMap-value={ texture } />
                        </mesh>
                        {/* <group>
                            <Html style={ { border: '2px solid white', padding: '0.5rem 1.4rem'  } }>
                                <p>Test</p>
                            </Html>
                        </group> */}
                    </>
                )}
            </CubeCamera>
            {/* <Center>
                <Text3D 
                    ref={ text } 
                    font={ './fonts/mohave-semibold.json' } 
                    position={ [ 0, 0, 10 ] } 
                    rotation={ [ 0, Math.PI, 0 ] }
                    scale={ [ 1.5, 1.5, 1.5 ] }
                >
                    CREATIVE TECHNOLOGIST
                    <meshBasicMaterial />
                </Text3D>
            </Center> */}
        </Suspense>
        
    )
}