import { createContext, useState } from 'react';

export const AppContext = createContext();

// essentially used to store and set global variables
export function AppContextProvider({ children })
{
    const [ color, setColor ] = useState('red');
    const [ user, setUser ] = useState('sam');

    const context = {
        color,
        setColor,
        user,
        setUser
    }

    return (
        <AppContext.Provider value={ context } >
            { children }
        </AppContext.Provider>
    )
}