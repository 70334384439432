import { useContext, useEffect } from 'react';
import NavBar from '../common/NavBar';
import { AppContext } from '../common/AppContext.jsx';

export default function AboutMe()
{
    const appCtx = useContext(AppContext);

    useEffect(() => {

        console.log('color', appCtx.color);
        appCtx.setColor('blue');
        console.log('color', appCtx.color);
        
    }, [appCtx.color]);

    return (
        <>
            <NavBar />
        </>
    )
}