import { useEffect, useState } from "react";
import { Vector3 } from 'three';

export default function useMousePosition()
{
    const [ mousePositon, setMousePosition ] = useState( { x: 0, y: 0 } );
    const [ normalizedMousePosition, setNormalizedMousePosition ] = useState( { x: 0, y: 0 } );

    const updateMousePosition = (e) =>
    {
        setMousePosition({ x: e.clientX, y: e.clientY });
        setNormalizedMousePosition({ 
            x: (e.clientX / window.innerWidth) * 2 - 1, 
            y: -(e.clientY / window.innerHeight) * 2 + 1
        });
    }

    useEffect(() =>
    {
        window.addEventListener('mousemove', updateMousePosition);

        return () =>
        {
            window.removeEventListener('mousemove', updateMousePosition);
        }

    }, []);

    return [mousePositon, normalizedMousePosition];
}